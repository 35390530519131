<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("generic.filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <label>{{ $t('generic.search') }}</label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="query.search"
              debounce="1000"
              :placeholder="$t('generic.search')"
            />
          </b-input-group>
        </b-col>
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-1"
        >
          <label for="club-country-id">{{ $t('generic.country') }}</label>
          <v-select
            id="club-country-id"
            v-model="query.countryId"
            class="select2-secondary"
            label="name"
            :options="countries"
            :reduce="country => country.id"
            :placeholder="$t('generic.selectCountry')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="club-state-id">{{ $t('generic.state') }}</label>
          <v-select
            id="club-state-id"
            v-model="query.stateId"
            label="name"
            :options="states"
            :disabled="query.countryId == null"
            :reduce="state => state.id"
            :placeholder="$t('generic.selectState')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('corporate.club') }}</label>
          <v-select
            v-model="query.clubId"
            :placeholder="$t('corporate.selectSportClub')"
            label="name"
            :options="clubs"
            :reduce="club => club.id"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="activity-id">{{ $t('generic.activity') }}</label>
          <v-select
            id="activity-id"
            v-model="query.activityId"
            label="name"
            :options="actions"
            :reduce="action => action.id"
            :placeholder="$t('generic.selectActivity')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t("generic.entity") }}</label>
          <v-select
            v-model="query.modelId"
            :placeholder="$t('generic.selectEntity')"
            label="name"
            :options="models"
            :reduce="model => model.id"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label> {{ $t('generic.dateRange') }}</label>
          <flat-pickr
            id="log-date-range"
            v-model="query.dateRange"
            class="form-control"
            :config="config"
            :placeholder="$t('generic.selectDateRange')"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
// eslint-disable-next-line import/extensions
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import vSelect from 'vue-select'
import servicesCountry from '@/services/countryService'
import servicesClubs from '@/services/clubsService'
import { actions, models } from '@/services/catalogsService'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    vSelect,
    flatPickr,
  },

  props: {
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      query: {
        search: null,
        countryId: null,
        stateId: null,
        clubId: null,
        activityId: null,
        modelId: null,
        dateRange: null,
      },
      countries: [],
      states: [],
      clubs: [],
      actionList: [],
      modelList: [],
      config: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        altFormat: 'Y-m-d',
        locale: Spanish,
        // minDate: 'today',
      },
    }
  },

  computed: {
    actions() {
      return this.actionList.map(e => ({ id: e.hash, name: e.labelables[0].label }))
    },
    models() {
      return this.modelList.map(e => ({ id: e.hash, name: e.labelables[0].label }))
    },
  },

  watch: {
    '$i18n.locale': function (newVal) {
      this.assignConfigLocale(newVal)
      this.fetchActions()
      this.fetchModels()
    },
    'query.countryId': function queryCountryId(pCountry, pOld) {
      if (pCountry != null) {
        if (pOld != null) {
          if (pOld != pCountry) {
            this.clearStateAndClub()
            this.getStates(pCountry)
            this.getClubs()
          }
        } else {
          this.clearStateAndClub()
          this.getStates(pCountry)
          this.getClubs()
        }
      } else {
        this.clearStateAndClub()
      }
      this.$emit('filter-query', this.query)
    },
    'query.stateId': function queryStateId(pState, pOldS) {
      if (pState != null) {
        if (pOldS != null) {
          if (pOldS != pState) {
            this.clearState()
          }
        } else {
          this.clearState()
        }
      } else {
        this.clearState()
      }
      this.$emit('filter-query', this.query)
    },
    'query.clubId': function queryClubId() {
      this.$emit('filter-query', this.query)
    },
    'query.activityId': function queryActivityId() {
      this.$emit('filter-query', this.query)
    },
    'query.modelId': function queryModelId() {
      this.$emit('filter-query', this.query)
    },
    'query.search': function querySearch() {
      this.$emit('filter-query', this.query)
    },
    'query.dateRange': function dateRange() {
      this.$emit('filter-query', this.query)
    },
  },

  mounted() {
    this.getCountries()
    this.fetchActions()
    this.fetchModels()
  },

  methods: {
    clearStateAndClub() {
      this.query.stateId = null
      this.states = []
      this.query.clubId = null
      this.clubs = []
    },
    clearState() {
      this.query.clubId = null
      this.clubs = []
      this.getClubs()
    },
    assignConfigLocale(pLocale) {
      if (pLocale == 'es') {
        this.config.locale = Spanish
        this.config.dateFormat = 'd-m-Y'
      } else if (pLocale == 'en') {
        this.config.locale = null
        this.config.dateFormat = 'Y-m-d'
      }
    },
    getCountries() {
      servicesCountry.getCountries().then(({ data }) => {
        this.countries = data.data.map(e => ({ id: e.hash, name: e.labelables[0].label }))
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    getStates(countryHash) {
      const filter = { country_hash: countryHash }
      servicesCountry.getStates(filter)
        .then(({ data }) => {
          this.states = data.data.map(e => ({ id: e.hash, name: e.name }))
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    getClubs() {
      const filter = {
        status: 1,
        country_hash: this.query.countryId,
        state_hash: this.query.stateId,
      }
      servicesClubs.getClubs({}, filter)
        .then(({ data }) => {
          this.clubs = data.data.map(e => ({ id: e.hash, name: e.club_name }))
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    fetchActions() {
      actions().then(({ data }) => {
        this.actionList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    fetchModels() {
      models().then(({ data }) => {
        this.modelList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
