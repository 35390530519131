import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getLogs(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`logs${query}`)
  },

  async getLogInfo(id, data) {
    return await apiInstance.get(`logs/${id}`, { params: data })
  },

  async getRelationDetail(id, data) {
    return await apiInstance.get(`logs/relation/${id}`, { params: data })
  },
}
