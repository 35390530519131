<template>
  <div>
    <!-- filters -->
    <filter-logs
      :check-permission="checkPermission"
      @filter-query="handleFilters"
    />
    <!--/ filters -->
    <b-card class="mb-0">
      <b-row>
        <b-col cols="12">
          <b-overlay
            :show="isLoading"
            opacity="1"
            :class="isLoading ? 'p-2 mb-1 mt-1' : ''"
          >
            <template #overlay>
              <div class="d-flex align-items-center justify-content-center mt-1">
                <b-spinner
                  type="border"
                  variant="primary"
                />
              </div>
              <div class="d-flex justify-content-center">
                <p class="pt-1">
                  {{ $t('loading') }}
                </p>
              </div>
            </template>
            <!-- table -->
            <b-table
              ref="refLogTable"
              class="position-relative mt-1"
              responsive
              :fields="headerTableLog"
              :items="logList"
              primary-key="id"
              show-empty
              :empty-text="$t('generic.noRecordsFound')"
            >
              <!-- Column: created_at -->
              <template #cell(created_at)="data">
                <span>{{ data.item.created_at | formatDateTime }}</span>
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <detail-component
                  :record="data.item"
                  :color-primary-btn="colorPrimaryBtn"
                />
              </template>
            </b-table>
            <!--/ table -->
          </b-overlay>
          <!-- paginador -->
          <app-paginator
            :data-list="paginate"
            @pagination-data="changePaginate"
          />
          <!--/ paginador -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import checkPermission from '@/auth/permissions'
import logService from '@/services/logService'
import AppPaginator from '../components/app-paginator/AppPaginator.vue'
import FilterLogs from './FilterLogs.vue'
import DetailComponent from './Detail.vue'

export default {
  components: {
    AppPaginator,
    FilterLogs,
    DetailComponent,
  },

  data() {
    return {
      isLoading: false,
      filters: {
        name: null,
        action_hash: null,
        model_description_hash: null,
        country_hash: null,
        state_hash: null,
        club_hash: null,
      },
      paginate: {
        from: 1,
        to: 15,
        total: 0,
      },
      logList: [],
    }
  },

  computed: {
    ...mapGetters({
      rolUserAuth: 'role',
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),

    headerTableLog() {
      return [
        {
          key: 'full_name',
          label: this.$t('generic.createdBy'),
          sortable: true,
        },
        {
          key: 'club_name',
          label: this.$t('corporate.club'),
          sortable: true,
        },
        {
          key: 'action_label',
          label: this.$t('generic.activity'),
          sortable: true,
        },
        {
          key: 'model_label',
          label: this.$t('generic.entity'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('generic.registrationDate'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchLogs()
    },
    'paginate.from': function () {
      this.fetchLogs()
    },
    'paginate.to': function () {
      this.fetchLogs()
    },
  },

  mounted() {
    if (this.checkPermission(['VIEW_LOGS_INFO'])) {
      this.fetchLogs()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },

  methods: {
    checkPermission,
    fetchLogs() {
      const params = {
        included: '',
        perPage: this.paginate.to,
        page: this.paginate.from,
      }
      this.isLoading = true

      logService.getLogs(params, this.filters).then(({ data }) => {
        this.paginate.total = data.total
        this.logList = data.data
        this.isLoading = false
      })
    },
    handleFilters(filterSelect) {
      if (filterSelect) {
        this.filters.name = filterSelect.search
        this.filters.action_hash = filterSelect.activityId
        this.filters.model_description_hash = filterSelect.modelId
        this.filters.country_hash = filterSelect.countryId
        this.filters.state_hash = filterSelect.stateId
        this.filters.club_hash = filterSelect.clubId
        this.filters.date_range = filterSelect.dateRange
      }
      this.fetchLogs()
    },
    changePaginate(paginateData) {
      this.paginate.to = paginateData.perPage
      this.paginate.from = paginateData.page
    },
  },
}
</script>
